export const getMargin = (partida) => {
  if (partida.hasOwnProperty("margin")) {
    return parseFloat(partida.margin)
  } else {
    return parseFloat(partida.costeGeneral) +
      parseFloat(partida.costeIndirecto) +
      parseFloat(partida.industrialProfit)
  }
}

export const calcMarginFactor = (partida) => {
    return (1 - getMargin(partida) / 100)
}

export const calcMarginBetween = (cost, price) => {
  //console.log(`calcMarginBetween: cost => ${cost}, price => ${price}`)
  var margin = 100 * (1 - cost / price)
  //console.log(`calcMarginBetween: margin => ${margin}`)
  return margin
}

export const calcCostTotal = (partida) => {
  var costTotal = parseFloat(partida.price) * parseFloat(partida.quantity)
  //console.log(`calcCostTotal: costTotal => ${costTotal}`)
  return costTotal
}

export const calcCostRealTotal = (partida) => {
  var costRealTotal = parseFloat(
    partida.hasOwnProperty("costReal")
      ? partida.costReal
      : partida.price) * parseFloat(partida.quantity)
  //console.log(`calcCostRealTotal: costRealTotal => ${costRealTotal}`)
  return costRealTotal
}

export const calcApplyDiscount = (price, discount) => {
    return price * (1 - discount / 100)
}

export const calcClientPriceWithoutDiscount = (partida) => {
  return parseFloat(partida.price) / calcMarginFactor(partida)
}

export const calcClientPrice = (partida) => {
  return calcClientPriceWithoutDiscount(partida) * (1 - parseFloat(partida.discount) / 100)
}

export const calcPriceTotal = (partida) => {
  var priceTotal = calcCostTotal(partida) / calcMarginFactor(partida)
  //console.log(`calcPriceTotal: priceTotal => ${priceTotal}`)
  return priceTotal
}

export const costRealCalc = (partida) => {
  //console.log(`partida: ${JSON.stringify(partida)}`)
  return parseFloat(partida.costReal) || parseFloat(partida.price)
}

export const costRealTotalCalc = (partida) => {
  console.log('costRealTotalCalc:', costRealCalc(partida), partida.quantity)
  return costRealCalc(partida) * parseFloat(partida.quantity)
}

export const marginRealCalc = (partida) => {
  return calcPriceTotal(partida) - calcCostRealTotal(partida) ||
    calcPriceTotal(partida) - calcCostTotal(partida)
}

export const marginRealPercCalc = (partida) => {
    return partida.costReal
            ? calcMarginBetween(partida.costReal, partida.clientPrice)
            : calcMarginBetween(partida.price, partida.clientPrice)
}

export const underObjective = (partida) => {
    return partida.costReal && partida.costReal < partida.price
}

export const overObjective = (partida) => {
    return partida.costReal && partida.costReal > partida.price
}

export const overPrice = (partida) => {
    return partida.costReal && partida.costReal > partida.clientPrice
}
