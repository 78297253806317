import React, {useState, useRef, useEffect} from "react"
import PropTypes from "prop-types"
import i18n from "i18n";
import CurrencyInput from 'react-currency-input-field';
import { TextareaAutosize } from '@mui/base';
import styled from 'styled-components';
import { DeleteOutline } from "@mui/icons-material";

const CustomInput= styled.input`
text-align: end;
width: 385.13px;
height: 35px;
border: 1px solid #C4C4C4;
box-sizing: border-box;
border-radius: 6px;
font-family: 'Helvetica';
font-style: normal;
font-weight: 300;
color: #414141;
margin-bottom: 9px;
padding-left:8px;

&::placeholder {
    font-family: "Helvetica";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    color: #B7B7B7;
}

&:focus {
    outline: none !important;
    border:1px solid #FFD52B !important;
}

&:hover {
    &::placeholder {
        color: #414141;
    }
    z-index:10;
    border: 1px solid #414141;
}`

const CustomTextarea = styled(TextareaAutosize)`
    padding-top: 5px;
    padding-bottom: 15px;
    display: block;
    width: 100%;
    overflow: hidden;
    font-family: "Helvetica";
    font-weight: 500;
    line-height: 19.5px;
    font-size:14px;
    min-height: 25px;
    margin-top: 10px;
    color: #414141;
    border: 0px;
    resize: none;
    justify-content:'left';
    text-align:'left',
    :focus-within {
        border: 1px solid #FFD52B !important;
    }
    :hover:not(:focus-within) {
        border: 1px solid #414141 !important;
        z-index:10;
    }
    :empty:focus::before, :empty::before {
        content: 'Escribir';
        color: lightgray;
    }
`

const Medicion = ({medicion, mediciones, index, setMediciones, fieldsToHide}) => {
    const sizeRef = useRef(null)
    const [size, setSize] = useState({height:28})
    const [descriptionSize, setDescriptionSize] = useState(40)
    const calculateDescriptionSize = () => {
        if(fieldsToHide.depth && fieldsToHide.width && fieldsToHide.height){
            setDescriptionSize(73)
        }else if(fieldsToHide.depth && fieldsToHide.width){
            setDescriptionSize(62)
        }else if(fieldsToHide.depth && fieldsToHide.height){
            setDescriptionSize(62)
        }else if(fieldsToHide.width && fieldsToHide.height){
            setDescriptionSize(62)
        }else if(fieldsToHide.depth){
            setDescriptionSize(51)
        }else if(fieldsToHide.width){
            setDescriptionSize(51)
        }else if(fieldsToHide.height){
            setDescriptionSize(51)
        }else{
            setDescriptionSize(40)
        }       
    }

    useEffect(() => {
        calculateDescriptionSize()
    }, [fieldsToHide])

    

    const handleDescriptionChange = (e) => {
        setMediciones((prevArray) => {
            const newArray = [...prevArray];
            const updatedObject = { ...newArray[index] };
            updatedObject.description = e.target.value;
            newArray[index] = updatedObject;
            return newArray;
          });
    }

    const handleChange = (e) => {
   
        setMediciones((prevArray) => {
            const newArray = [...prevArray];
            const updatedObject = { ...newArray[index] };
            updatedObject[e.target.name] = e.target.value;
            if(updatedObject.quantity && updatedObject.depth && updatedObject.width && updatedObject.height){
                
                        updatedObject.total = updatedObject.quantity * updatedObject.depth * updatedObject.width * updatedObject.height;
                
            }
            newArray[index] = updatedObject;

            return newArray;
            });
    }

    const deleteMedicion = (e, index) => {
        e.preventDefault();
        setMediciones((prevArray) => {
            const newArray = [...prevArray];
            newArray.splice(index, 1);
            return newArray;
          });
    }

    useEffect(()=>{
        if(sizeRef.current){
            const charWidth = 8
            const charsLength = medicion.description.length * charWidth
            const rows = Math.floor(charsLength/sizeRef.current.clientWidth)+1
            setSize({
                height: rows* 18,
                width: sizeRef.current.clientWidth
            })
        }
    }, [sizeRef.current, medicion.description])


    useEffect(()=>{
        if(medicion.quantity && medicion.depth && medicion.width && medicion.height){
            if(medicion.quantity>0 && medicion.depth>0 && medicion.width>0 && medicion.height>0){
         
                    medicion.total = medicion.quantity * medicion.depth * medicion.width * medicion.height;
                  
                
            }
        }
    }, [medicion])

    return(
        <div id='rowHoverNoColor' className="inline" style={{width:'100%',  marginBottom: `-${size.height-14}px` }}>
            <div style={{width:descriptionSize+'%', textAlign:'start'}}>
            <CustomTextarea
                placeholder='Escribir descripción'
                value={medicion.description}
                style={{
                    height: `${size.height+14}px`, 
                    position:"relative",
                    border:"1px solid #C4C4C4",
                    background:"white",
                    marginBottom:"-12.5px",
                    marginTop: '-4px', 
                    width:'100%', 
                    padding:'3px 10px', 
                    paddingTop:'4px',
                    minWidth:'85px', 
                    borderRadius:'0px',
                    borderBottomLeftRadius:  mediciones.length-1 == index ? '6px' : '0px' ,
                    borderTopLeftRadius: index>0 ? '0px' : '6px',
                    
                }}
                ref={sizeRef}
                onChange={(e)=>{
                    handleDescriptionChange(e)
                }}
            />
            </div>
            <div style={{width:'11%', textAlign:'end'}}>
                <CustomInput
                type="number"
                    value={medicion.quantity}
                    decimalsLimit={10}
                    decimalSeparator=","
                    groupSeparator='.'
                    name="quantity"
                    onChange={(e)=>{handleChange(e)}}
                    placeholder={i18n.t('0')}
                    style={{
                        height: `${size.height+14}px`,
                        position:"relative", 
                        minHeight: '25px', marginTop:'-4px', marginBottom:'-2px',
                        width:'100%', padding:'3px 10px', borderRadius:'0px',
                        }} 
                />
            </div>
            {
                fieldsToHide.depth ? null :
            
            <div style={{width:'11%', textAlign:'end'}}>
                <CustomInput
                  type="number"
                    value={medicion.depth}
                    decimalsLimit={10}
                    decimalSeparator=","
                    groupSeparator='.'
                    name="depth"
                    onChange={(e)=>{handleChange(e)}}
                    placeholder={i18n.t('0')}
                    style={{
                        height: `${size.height+14}px`,
                        position:"relative", 
                        minHeight: '25px', marginTop:'-4px', marginBottom:'-2px',
                        width:'100%', padding:'3px 10px', borderRadius:'0px',
                        }} 
                />
            </div>
            }
            {
                fieldsToHide.width ? null :
            
            <div style={{width:'11%', textAlign:'end'}}>
                <CustomInput
                  type="number"
                    value={medicion.width}
                    decimalsLimit={10}
                    decimalSeparator=","
                    groupSeparator='.'
                    name="width"
                    onChange={(e)=>{handleChange(e)}}
                    placeholder={i18n.t('0')}
                    style={{
                        height: `${size.height+14}px`,
                        position:"relative", 
                        minHeight: '25px', marginTop:'-4px', marginBottom:'-2px',
                        width:'100%', padding:'3px 10px', borderRadius:'0px',
                        }} 
                />
            </div>
            }
            {
                fieldsToHide.height ? null :
            <div style={{width:'11%', textAlign:'end'}}>
                <CustomInput
                  type="number"
                    value={medicion.height}
                    decimalsLimit={10}
                    decimalSeparator=","
                    groupSeparator='.'
                    name="height"
                    onChange={(e)=>{handleChange(e)}}
                    placeholder={i18n.t('0')}
                    style={{
                        height: `${size.height+14}px`,
                        position:"relative", 
                        minHeight: '25px', marginTop:'-4px', marginBottom:'-2px',
                        width:'100%', padding:'3px 10px', borderRadius:'0px',
                        }} 
                />
            </div>
            }
            <div style={{width:'11%', textAlign:'end'}}>
                <CustomInput
                type="number"
                    value={medicion?.total?.toFixed(2)}
                    decimalsLimit={10}
                    decimalSeparator=","
                    groupSeparator='.'
                    
                    name="total"
                    placeholder={i18n.t('0')}
                    style={{
                        height: `${size.height+14}px`,
                        position:"relative", 
                        minHeight: '25px', marginTop:'-4px', marginBottom:'-2px',
                        width:'100%', padding:'3px 10px', borderRadius:'0px',
                        borderBottomRightRadius: mediciones.length-1 === index ? '6px' : '0px' ,
                        borderTopRightRadius: index==0 ? '6px' : '0px'
                        }} 
                />
            </div>
            {
            
            <DeleteOutline onClick={(e)=>{deleteMedicion(e, index)}} id='showCheckbox' style={{fontSize:'18px', display:'none', color:'#414141', marginTop:'2px', marginLeft:'8px', cursor:'pointer'}}></DeleteOutline>
            }
        </div>
    )
}
Medicion.propTypes = {
    medicion: PropTypes.object.isRequired,
    mediciones: PropTypes.array.isRequired,
    index: PropTypes.any,
    setMediciones: PropTypes.any,
    fieldsToHide: PropTypes.array.isRequired
}
export default Medicion