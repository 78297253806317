import { TenMp } from "@mui/icons-material"
import Part from "./Part"
class Chapter {
  constructor(data) {
    //FROM DATABASE
    this.parentId = data.capituloInfo.parentId
    this.partidas = data.partidas
      ? data.partidas.map(partida => new Part(partida))
      : []
    this.chapterId = data.capituloInfo.chapterId
    this.subcapitulos = data.subcapitulos
      ? data.subcapitulos.map(subcapitulo => new Chapter(subcapitulo))
      : []
    this.status = "OPEN" //deprecated
    ;(this.updates = data.capituloInfo.updates
      ? data.capituloInfo.updates
      : null), //deprecated
      (this.childUpdates = data.capituloInfo.childUpdates
        ? data.capituloInfo.childUpdates
        : null) //deprecated
    this.sended = data.capituloInfo.sended ? data.capituloInfo.sended : false // for providers
    this.selfManagement = data.capituloInfo.selfManagement
      ? data.capituloInfo.selfManagement
      : false //for providers
    this.price = data.capituloInfo.price
      ? data.capituloInfo.price
      : data.price
      ? data.price
      : 0
    this.clientPrice = data.capituloInfo.clientPrice
      ? data.capituloInfo.clientPrice
      : data.clientPrice
      ? data.clientPrice
      : data.finalPrice
      ? data.finalPrice
      : 0
    this.tags = data.capituloInfo.tags ? data.capituloInfo.tags : [] // future
    this.name = data.capituloInfo.name
    this.customCode = data.capituloInfo.customCode
      ? data.capituloInfo.customCode
      : null // deprecated
    this.capituloInfo = data.capituloInfo
    this.position = data.capituloInfo.position ? data.capituloInfo.position : 0
    this.root = data.capituloInfo.root ? data.capituloInfo.root : "false"
    this.accountId = data.capituloInfo.accountId
    this.projectId = data.capituloInfo.projectId
    this.comeFrom = data.capituloInfo.comeFrom
      ? data.capituloInfo.comeFrom
      : null
    //ONLY FOR FRONTEND
    this.empty = false
    this.warning = false
    this.checked = false
    this.isExpanded = false
    this.totalCertPreviousChapter = data.capituloInfo.totalCertPreviousChapter
      ? data.capituloInfo.totalCertPreviousChapter
      : 0
    this.totalCertCurrentChapter = data.capituloInfo.totalCertCurrentChapter
      ? data.capituloInfo.totalCertCurrentChapter
      : 0
    this.totalCertificateProject = data.capituloInfo.totalCertificateProject
      ? data.capituloInfo.totalCertificateProject
      : 0
    this.totalPreviousCertificateProject = data.capituloInfo
      .totalPreviousCertificateProject
      ? data.capituloInfo.totalPreviousCertificateProject
      : 0
    this.totalCurrentCertificateProject = data.capituloInfo
      .totalCurrentCertificateProject
      ? data.capituloInfo.totalCurrentCertificateProject
      : 0
    this.total = data.capituloInfo.price ? data.capituloInfo.price : 0
    this.capituloInfo = data.capituloInfo
    this.totalDiscount = data.totalDiscount
    this.clientPriceWithoutDiscount = data.capituloInfo
      .clientPriceWithoutDiscount
      ? data.capituloInfo.clientPriceWithoutDiscount
      : data.clientPriceWithoutDiscount
      ? data.clientPriceWithoutDiscount
      : data.finalPriceWithoutDiscount
      ? data.finalPriceWithoutDiscount
      : 0
    //this.updateCostReal()
  }
  computeCosts() {
    this.cost = 0
    this.finalCost = 0
    this.finalCostWithDiscount = 0
    for (let ch of this.subcapitulos) {
      ch.computeCosts()
      this.cost += ch.cost
      this.finalCost += ch.finalCost
      this.finalCostWithDiscount += ch.finalCostWithDiscount
    }
    for (let part of this.partidas) {
      this.cost += part.cost
      this.finalCost += part.finalCost
      this.finalCostWithDiscount += part.finalCostWithDiscount
    }
  }

  update(data) {
    this.capituloInfo = data
  }
  addCapitulo(capitulo) {
    this.subcapitulos.push(capitulo)
  }
  updateTotalDiscount(discount) {
    this.totalDiscount = discount
  }
  calculatePreviousTotalChapter = () => {
    this.totalCertPreviousChapter = 0

    if (this.partidas && this.partidas.length > 0) {
      for (let partida of this.partidas) {
        this.totalCertPreviousChapter +=
          partida.cert_previous * partida.clientPrice
      }
    } else if (this.subcapitulos && this.subcapitulos.length > 0) {
      for (let subChapter of this.subcapitulos) {
        this.totalCertPreviousChapter +=
          subChapter.calculatePreviousTotalChapter()
      }
    }

    return this.totalCertPreviousChapter
  }

  calculateCurrentTotalChapter = () => {
    this.totalCertCurrentChapter = 0

    if (this.partidas && this.partidas.length > 0) {
      for (let partida of this.partidas) {
        this.totalCertCurrentChapter +=
          partida.cert_current * partida.clientPrice
      }
    } else if (this.subcapitulos && this.subcapitulos.length > 0) {
      for (let subChapter of this.subcapitulos) {
        this.totalCertCurrentChapter +=
          subChapter.calculateCurrentTotalChapter()
      }
    }
    return this.totalCertCurrentChapter
  }

  calculateFooterValues = () => {
    //let sumTotal = 0;
    if (this.subcapitulos.length > 0) {
      this.totalCertificateProject = this.subcapitulos.reduce(
        (total, chapter) =>
          total +
          chapter.totalCertPreviousChapter +
          chapter.totalCertCurrentChapter,
        0
      )
      this.totalPreviousCertificateProject = this.subcapitulos.reduce(
        (total, chapter) => total + chapter.totalCertPreviousChapter,
        0
      )
      this.totalCurrentCertificateProject = this.subcapitulos.reduce(
        (total, chapter) => total + chapter.totalCertCurrentChapter,
        0
      )
    }
  }

  updateCostReal = () => {
    this.costReal = this.partidas.reduce(
      (costReal, partida) => costReal + (
        partida.costReal 
          ? partida.costReal * partida.quantity
          : partida.price * partida.quantity
      ),
      0
    )
    this.costReal = this.subcapitulos.reduce(
        (costReal, capitulo) => costReal + capitulo.costReal,
        this.costReal
        )
  }

  updateTotalPartidas = () => {
    const totals = this.partidas.reduce(
      (acc, partida) => {
        if (partida.contradicted_by_id != null) {
          return acc // Skip the current iteration if contradicted_by_id is not null
        }

        const price = partida.price * partida.quantity
	    const costReal = partida.costReal * partida.quantity
        const clientPrice = partida.clientPrice * partida.quantity
        const clientPriceWithoutDiscount =
          partida.clientPriceWithoutDiscount * partida.quantity
        const certCurrent = partida.cert_current * partida.clientPrice
        const certPrevious = partida.cert_previous * partida.clientPrice

        acc.price += price
	    acc.costReal += costReal
        acc.clientPrice += clientPrice
        acc.clientPriceWithoutDiscount += clientPriceWithoutDiscount
        acc.totalCertCurrentChapter += certCurrent
        acc.totalCertPreviousChapter += certPrevious

        return acc
      },
      {
        price: 0,
        costReal: 0,
        clientPrice: 0,
        clientPriceWithoutDiscount: 0,
        totalCertCurrentChapter: 0,
        totalCertPreviousChapter: 0,
      }
    )

    this.price = totals.price
    this.costReal = totals.costReal
    this.clientPrice = totals.clientPrice
    this.clientPriceWithoutDiscount = totals.clientPriceWithoutDiscount
    this.totalCertCurrentChapter = totals.totalCertCurrentChapter
    this.totalCertPreviousChapter = totals.totalCertPreviousChapter
  }

  updateTotalSubcapitulos = () => {
    for (let capitulo of this.subcapitulos) {
      capitulo.updateTotal()
      this.price += capitulo.price
      this.costReal += capitulo.costReal
      this.clientPrice += capitulo.clientPrice
      this.clientPriceWithoutDiscount += capitulo.clientPriceWithoutDiscount
      this.totalCertCurrentChapter += capitulo.totalCertCurrentChapter
      this.totalCertPreviousChapter += capitulo.totalCertPreviousChapter
    }
  }

  updateTotal = () => {
    this.price = 0
    this.costReal = 0
    this.clientPrice = 0
    this.clientPriceWithoutDiscount = 0
    this.totalCertCurrentChapter = 0
    this.totalCertPreviousChapter = 0

    if (this.partidas.length > 0) {
      this.updateTotalPartidas()
    } else if (this.subcapitulos.length > 0) {
      this.updateTotalSubcapitulos()
    }

    //    if (
    //      this.partidas.length == 0 &&
    //      this.isExpanded &&
    //      this.subcapitulos.length == 0
    //    ) {

    this.calculateFooterValues()
  }

  checkEmpty = () => {
    if (this.partidas.length == 0 && this.subcapitulos.length == 0) {
      this.empty = true
      this.warning = true
      return true
    } else if (this.partidas.length > 0) {
      for (let partida of this.partidas) {
        if (partida.empty) {
          this.warning = true
          return true
        }
      }
      this.warning = false
      return false
    } else if (this.subcapitulos.length > 0) {
      let hasWarnings = false
      for (let capitulo of this.subcapitulos) {
        if (capitulo.checkEmpty() === true) {
          hasWarnings = true
        }
      }
      this.warning = hasWarnings
      return hasWarnings
    }
    return false
  }

  checkUpdates = () => {
    if (this.partidas.length > 0) {
      for (let partida of this.partidas) {
        if (partida.updates) {
          this.updates = partida.updates
          return true
        }
      }
    } else if (this.subcapitulos.length > 0) {
      for (let capitulo of this.subcapitulos) {
        capitulo.checkUpdates()
        if (capitulo.updates != null) {
          this.updates = capitulo.updates
        }
      }
    }
  };

  *preOrder() {
    yield this
    for (let capitulo of this.subcapitulos) {
      yield* capitulo.preOrder()
    }
  }

  remove(capituloId) {
    for (let capitulo of this.preOrder()) {
      const filtered = capitulo.subcapitulos.filter(
        element => element.chapterId !== capituloId
      )
      if (capitulo.subcapitulos.length !== filtered.length) {
        capitulo.subcapitulos = filtered
        capitulo.updateTotal()
        break
      }
    }
    this.updateTotal()
  }

  removeAndSetPosition(capituloId) {
    for (let capitulo of this.preOrder()) {
      const filtered = capitulo.subcapitulos.filter(
        element => element.chapterId !== capituloId
      )
      if (capitulo.subcapitulos.length !== filtered.length) {
        capitulo.subcapitulos = filtered.map((c, i) => {
          c.position = i
          c.capituloInfo.position = i
          return c
        })
        capitulo.updateTotal()
        break
      }
    }
    this.updateTotal()
  }

  updatePostions(partidas) {
    for (let i = 0; i < partidas.length; i++) {
      partidas[i].position = i
    }
  }

  removePartida(capituloId, partidaId) {
    for (let capitulo of this.preOrder()) {
      if (capitulo.chapterId === capituloId) {
        const filtered = capitulo.partidas.filter(
          element => element.partidaId !== partidaId
        )
        if (capitulo.partidas.length !== filtered.length) {
          capitulo.partidas = filtered
          this.updatePostions(capitulo.partidas)
          capitulo.updateTotal()
          break
        }
      }
    }
    this.updateTotal()
  }

  removePartidaMove(capituloId, partidaId) {
    for (let capitulo of this.preOrder()) {
      if (capitulo.chapterId === capituloId) {
        const filtered = capitulo.partidas.filter(
          element => element.partidaId !== partidaId
        )
        if (capitulo.partidas.length !== filtered.length) {
          capitulo.partidas = filtered
          capitulo.updateTotal()
          break
        }
      }
    }
    this.updateTotal()
  }

  addPartida(capituloId, partida, indexPartida) {
    for (let capitulo of this.preOrder()) {
      if (capitulo.chapterId === capituloId) {
        partida.position = indexPartida
        capitulo.partidas.splice(indexPartida, 0, partida)
        capitulo.updateTotal()
        break
      }
    }
    this.updateTotal()
  }
  addCapitulo(parentId, capituloNue, indexCapitulo) {
    for (let capitulo of this.preOrder()) {
      if (parentId == "root") {
        capituloNue.parentId = "root"
        let aux = [...capitulo.subcapitulos]
        let aux2 = {
          capituloInfo: capituloNue.capituloInfo
            ? capituloNue.capituloInfo
            : capituloNue.chapter,
          position: indexCapitulo,
          subcapitulos: capituloNue.subcapitulos,
          partidas: capituloNue.partidas
            ? capituloNue.partidas.map(p => ({
                partidaId: p,
              }))
            : [],
        }
        let capi = new Chapter(aux2)
        let auxpos = capi.position
        capi.position = indexCapitulo
        capi.capituloInfo.position = indexCapitulo
        //aux.push(capi)
        aux.splice(indexCapitulo, 0, capi)
        capitulo.subcapitulos = aux
        //capitulo.updateTotal()
        capitulo.price = parseFloat(capitulo.price) + parseFloat(capituloNue.price)
        capitulo.costReal = parseFloat(capitulo.costReal) + parseFloat(capituloNue.costReal)
        capitulo.clientPriceWithoutDiscount =
          parseFloat(capitulo.clientPriceWithoutDiscount) +
          parseFloat(capituloNue.clientPriceWithoutDiscount)
        break
      }
      if (capitulo.chapterId === parentId) {
        let aux = [...capitulo.subcapitulos]
        let aux2 = {
          capituloInfo: capituloNue.capituloInfo,
          subcapitulos: [],
          partidas: capituloNue.partidas
            ? capituloNue.partidas.map(p => ({
                partidaId: p,
              }))
            : [],
        }
        let capi = new Chapter(aux2)
        capi.position = indexCapitulo
        capi.capituloInfo.position = indexCapitulo
        //aux.push(capi)
        aux.splice(indexCapitulo, 0, capi)
        capitulo.subcapitulos = aux

        capitulo.price =
          parseFloat(capitulo.price) + parseFloat(capituloNue.price)
        capitulo.clientPriceWithoutDiscount =
          parseFloat(capitulo.clientPriceWithoutDiscount) +
          parseFloat(capituloNue.clientPriceWithoutDiscount)
        //capitulo.updateTotal()

        break
      }
    }
    this.updateTotal()
  }
  updateIsExpanded(capituloId) {
    for (let capitulo of this.preOrder()) {
      if (capitulo.chapterId === capituloId) {
        capitulo.isExpanded = !capitulo.isExpanded
        break
      }
    }
  }
  getInfoSelected() {
    let selectedInfo = []
    for (let capitulo of this.preOrder()) {
      if (capitulo.checked) {
        selectedInfo.push(capitulo.capituloInfo)
      } else {
        for (let partida of capitulo.partidas) {
          if (partida.checked) {
            selectedInfo.push(partida.partidaInfo)
          }
        }
      }
    }
    return selectedInfo
  }
  checkPartida(capituloId, partidaId) {
    for (let capitulo of this.preOrder()) {
      if (capitulo.chapterId === capituloId) {
        for (let partida of capitulo.partidas) {
          if (partida.partidaId === partidaId) {
            partida.checked = !partida.checked
            break
          }
        }
      }
    }
  }
  checkCapitulo(capituloId, value) {
    for (let capitulo of this.preOrder()) {
      if (capitulo.chapterId === capituloId) {
        capitulo.checked = value
        if (capitulo.subcapitulos.length > 0) {
          for (let subcapitulo of capitulo.subcapitulos) {
            subcapitulo.checkCapitulo(subcapitulo.chapterId, value)
          }
        }
        if (capitulo.partidas.length > 0) {
          for (let partida of capitulo.partidas) {
            partida.checked = value
          }
        }
        break
      }
    }
  }

  updatePartida(newData, capituloId, final) {
    for (let capitulo of this.preOrder()) {
      if (capitulo.chapterId === capituloId) {
        for (let partida of capitulo.partidas) {
          if (partida.partidaId === newData.partidaId.partidaId) {
            partida.updatePartida(newData)
            break
          }
        }
        break
      }
    }

    this.updateTotal()
  }
  updateCapitulo(newData, capituloId) {
    for (let capitulo of this.preOrder()) {
      if (capitulo.chapterId === capituloId) {
        capitulo.update(newData)
        break
      }
    }
  }

  isEmpty() {
    for (let capitulo of this.preOrder()) {
      if (capitulo.subcapitulos.length == 0) {
        if (capitulo.partidas.length == 0) {
          //capitulo.update(capitulo)
        }
      }
    }
  }
  getChaptersToGroup() {
    let chaptersToGroup = []
    for (let capitulo of this.subcapitulos) {
      if (capitulo.checked === true) {
        chaptersToGroup.push(capitulo.capituloInfo)
      } else {
        chaptersToGroup = chaptersToGroup.concat(capitulo.getChaptersToGroup())
      }
    }
    return chaptersToGroup
  }

  postGroupParts(newChapter, newParts, oldParts) {
    try {
      for (let part of oldParts) {
        this.removePartida(part.chapterId, part.partidaId)
      }
      let chapterParentId = newChapter.parentId
      const chapter = new Chapter({ capituloInfo: newChapter, partidas: [] })
      if (chapterParentId === "root") {
        this.subcapitulos.push(chapter)
      } else {
        for (let capitulo of this.preOrder()) {
          if (capitulo.chapterId === chapterParentId) {
            capitulo.subcapitulos.push(chapter)
            break
          }
        }
      }
    } catch (error) {
      console.error(error)
    }
  }

  postGroupChapters(chapterCreated, newChapters, oldChapters) {
    try {
      let chapterParentId = chapterCreated.parentId
      let auxChapters = oldChapters
      for (let index = 0; index < auxChapters.length; index++) {
        auxChapters[index].parentId = chapterCreated.chapterId
      }
      const chapter = new Chapter({
        capituloInfo: { ...chapterCreated, name: "" },
        subcapitulos: [],
      })
      if (chapterParentId === "root") {
        this.subcapitulos.push(chapter)
      } else {
        for (let capitulo of this.preOrder()) {
          if (capitulo.chapterId === chapterParentId) {
            capitulo.subcapitulos.push(chapter)
            break
          }
        }
      }
      for (let chapter of oldChapters) {
        this.remove(chapter.chapterId)
      }
    } catch (error) {
      console.error(error)
    }
  }
  getChapterById(chapterId) {
    for (let capitulo of this.preOrder()) {
      if (capitulo.chapterId === chapterId) {
        return capitulo
      }
    }
  }

  //Fill chapter with parts or subchapters
  fillChapterContent(chapterId, chapterContent) {
    for (let capitulo of this.preOrder()) {
      if (capitulo.chapterId === chapterId) {
        const tmp = capitulo
        if (chapterContent["parts"].length > 0) {
          for (let part of chapterContent["parts"]) {
            tmp.partidas.push(new Part({ partidaId: part }))
          }
        }
        if (chapterContent["chapters"].length > 0) {
          tmp.subcapitulos = chapterContent["chapters"].map(
            sc => new Chapter({ capituloInfo: sc })
          )
        }
        capitulo = tmp
        break
      }
    }
  }

  removeAllPartsByChapterId(chapterId) {
    for (let capitulo of this.preOrder()) {
      if (capitulo.chapterId === chapterId) {
        capitulo.partidas = []
        break
      }
    }
  }

  fillAllFromRoot(chapterId, chapterContent) {
    const currentChapter = this.findChapterById(chapterId)
    if (!currentChapter) return

    const processSubChapters = subChapters => {
      if (subChapters?.length === 0) return

      subChapters?.forEach(subChapter => {
        const partList = subChapter.partidas.map(part => ({
          partidaId: part,
        }))

        const oochapter = new Chapter({
          capituloInfo: subChapter,
          partidas: partList,
        })

        oochapter.fillAllFromRoot(subChapter.chapterId, subChapter.chapters)
        oochapter.updateCostReal()
        oochapter.totalCertPreviousChapter =
          oochapter.calculatePreviousTotalChapter()
        oochapter.totalCertCurrentChapter =
          oochapter.calculateCurrentTotalChapter()

        currentChapter.subcapitulos.push(oochapter)
      })
    }

    processSubChapters(chapterContent)
  }

  findChapterById(chapterId) {
    for (const chapter of this.preOrder()) {
      if (chapter.chapterId === chapterId) {
        return chapter
      }
    }
    return null
  }

  //to refresh chapter without delay after editing some parts (only called in one special situation)
  fillChapterContent2(chapterId, chapterContent) {
    for (let capitulo of this.preOrder()) {
      if (capitulo.chapterId === chapterId) {
        const tmp = capitulo
        tmp.partidas = []
        tmp.subcapitulos = []
        if (chapterContent["parts"].length > 0) {
          for (let part of chapterContent["parts"]) {
            tmp.partidas.push(new Part({ partidaId: part }))
          }
        }
        if (chapterContent["chapters"].length > 0) {
          tmp.subcapitulos = chapterContent["chapters"].map(
            sc => new Chapter({ capituloInfo: sc })
          )
        }
        capitulo = tmp
        break
      }
    }
  }
  updateDiscount(newDiscount, capituloId) {
    for (let capitulo of this.preOrder()) {
      if (capitulo.chapterId === capituloId) {
        capitulo.updateTotalDiscount(newDiscount)
        break
      }
    }
  }

  movePartsByChapterIds = (parentId, chapterId) => {
    let partidas = null
    for (let capitulo of this.preOrder()) {
      if (capitulo.chapterId === parentId) {
        partidas = capitulo.partidas
        capitulo.partidas = []
        break
      }
    }

    for (let capitulo of this.preOrder()) {
      if (capitulo.chapterId === chapterId) {
        capitulo.partidas = partidas.map(part => {
          part.updateChapterId(chapterId)
          return part
        })
        break
      }
    }
  }
}

export default Chapter
