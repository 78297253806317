import React, { useEffect, useState, useContext} from 'react';
import CloseIcon from "@mui/icons-material/Close";
import {makeStyles} from "@material-ui/core/styles";
import { Modal} from "@mui/material";
import PropTypes from "prop-types";
import i18n from 'i18n';
import ModalSelectPlantilla from './modalSelectPlantilla';
import ModalCreateFromExcel from './ModalCreateFromExcel';
import ContactContext from "context/contact/contactContext"
import excel from "assets/customIcons/excel.svg"
import bc3 from "assets/customIcons/bc3.svg"
import startPlanhopper from "assets/customIcons/logos/startPlanhopper.svg"
import plantilla from "assets/customIcons/plantilla.svg"
import higherThan from "assets/customIcons/ArrowHigherThan.svg"

function getModalStyle() {
    const top = 50 ;
    const left = 50;
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };

}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        width: '500px',
       height: '690px',
       //height: '560px',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 6,
        maxHeight: 'calc(100vh - 100px)',
        overflowY: 'auto',
        overflowX:'hidden',
    },
}));

const ModalSelectTypeProject =({modalNew, setModalNew, setmodal_first, onClickPersonalModal})=> {

    const [ modalStyle ] = useState(getModalStyle);
    const classes = useStyles();
    const handleCargar = () => {
        setModalNew(false)
        setmodal_first(true)
    }
    const [openModalPlantilla, setOpenModalPlantilla] = useState(false);
    const [openModalExcel, setOpenModalExcel] = useState(false);
    const {Contacts} = useContext(ContactContext)

    return(
        <>
            <Modal
                open={modalNew}
                onClose={() => {
                    null
                }}
            >
                <div style={modalStyle} className={classes.paper}>
                <CloseIcon id="cerrar" onClick={() => {
                        setModalNew(false);
                    }}></CloseIcon>

                    <div className="CardProjects" style={{marginTop:'60px', marginLeft:'28px', cursor:'pointer', border:'1px solid #c4c4c4'}} onClick={onClickPersonalModal}>
                                                <div id="CardContent">
                                                    <div className="ImgProyect" style={{marginLeft:'0px'}}>
                                                    <span>
                                                        <img src={bc3}  height="75px" width='150px' />
                                                    </span>
                                                    </div>
                                                    <div id="CenterContentProyectCard" style={{marginLeft: '105px', marginRight:'0px'}}>
                                                        <div className="flex-grow-1 overflow-hidden" style={{marginRight:'-10px'}}>
                                                            <div id="AddProyecto" style={{fontSize:'16px', fontWeight:'700'}}>
                                                                    {i18n.t("Cargar Mediciones")}
                                                            </div>
                                                            <p className="DescriptionText" style={{fontSize:'13px'}}>Sube tu archivo bc3 y empieza a trabajar en tu presupuesto</p>
                                                        </div>
                                                        <img src={higherThan} style={{width:'24px', height:'15px', marginTop:'30px' }}/>
                                                    </div>
                                               
                                                </div>
                     </div>
                   
                     <div className="CardProjects" style={{marginTop:'0px', marginLeft:'28px', cursor:'pointer', border:'1px solid #c4c4c4'}} onClick={handleCargar}>
                                                <div id="CardContent">
                                                    <div className="ImgProyect" style={{marginLeft:'0px'}}>
                                                        <span>
                                                        <img src={startPlanhopper}   height="75px" width='150px' />
                                                    </span>
                                                    </div>
                                                    <div id="CenterContentProyectCard" style={{marginLeft: '105px'}}>
                                                        <div className="flex-grow-1 overflow-hidden" style={{marginRight:'-10px'}}>
                                                            <div id="AddProyecto" style={{fontSize:'16px', fontWeight:'700'}}>
                                                                    {i18n.t("Comienza con Planhopper")}
                                                            </div>
                                                            <p className="DescriptionText" style={{fontSize:'13px', marginRight:'0px', width:'95%'}}>
                                                                Construye tu presupuesto utilizando las partidas de las bases de datos
                                                            </p>
                                                        </div>
                                                        <img src={higherThan} style={{width:'24px', height:'15px', marginTop:'30px' }}/>
                                                    </div>
                                                </div>
                     </div>
                     <div className="CardProjects" style={{marginTop:'0px', marginLeft:'28px', border:'1px solid #c4c4c4'}}>
                            <div id="CardContent" style={{cursor:'pointer'}} 
                                 onClick={()=>{setOpenModalPlantilla(true);setModalNew(false)}}
                            >
                                <div className="ImgProyect" style={{marginLeft:'0px', marginBottom:'60px'}}>
                                <span>
                                        <img src={plantilla}  height="75px" width='150px' />
                                    </span>
                                </div>
                                <div id="CenterContentProyectCard" style={{marginLeft: '105px', marginRight:'0px'}}>
                                    <div className="flex-grow-1 overflow-hidden" style={{marginRight:'-10px'}}>
                                        <div id="AddProyecto" style={{fontSize:'16px', fontWeight:'700'}}>
                                                {i18n.t("Plantilla de referencia")}
                                        </div>
                                        <p className="DescriptionText" style={{fontSize:'13px'}}>
                                       Selecciona una plantilla y empieza a trabajar en tu presupuesto</p>
                                    </div>
                                    <img src={higherThan} style={{width:'24px', height:'15px', marginTop:'30px' }}/>
                                </div>
                            </div>
                           
                     </div>
                    {
                        
                    
                     <div className="CardProjects" style={{marginTop:'0px', marginLeft:'28px', border:'1px solid #c4c4c4'}}>
                            <div id="CardContent" style={{cursor:'pointer'}} 
                                 onClick={()=>{setOpenModalExcel(true); setModalNew(false)}}
                            >
                                <div className="ImgProyect" style={{marginLeft:'0px', marginBottom:'60px'}}>
                                    <span>
                                        <img src={excel}  height="75px" width='150px' />
                                    </span>
                                </div>
                                <div id="CenterContentProyectCard" style={{marginLeft: '105px', marginRight:'0px'}}>
                                    <div className="flex-grow-1 overflow-hidden" style={{marginRight:'-10px'}}>
                                        <div id="AddProyecto" style={{fontSize:'16px', fontWeight:'700'}}>
                                                {i18n.t("otherFormats")}
                                        </div>
                                        <p style={{fontSize:'13px', fontFamily:"Helvetica", marginRight:"18px"}}>
                                        Sube aquí <strong>Excel, PDF, CSV, CAD, Revit, TXT, DOC (Word)</strong> y recibirás un correo cuando esté cargado.</p>
                                    </div>
                                    <img src={higherThan} style={{width:'24px', height:'15px', marginTop:'30px' }}/>
                                </div>
                                
                            </div>
                           
                     </div>
                      
                    }
                     
                 </div>

                   
        
            </Modal>
            {openModalPlantilla && <ModalSelectPlantilla open={openModalPlantilla} setOpen={setOpenModalPlantilla}/>}
            <ModalCreateFromExcel
            modal_first={openModalExcel}
            setmodal_first={setOpenModalExcel}
            contactos={Contacts}
             />
        </>
    )
}
ModalSelectTypeProject.propTypes = {
    modalNew: PropTypes.bool, 
    setModalNew: PropTypes.func,
    setmodal_first: PropTypes.func,
    onClickPersonalModal: PropTypes.func
}
  

export default ModalSelectTypeProject;