import isNil from "lodash"

class PartCertHistory {
  constructor(data) {
    this.accountId = data.accountId
    this.certCurrent = data.cert_current
    this.certPrevious = data.cert_previous
    this.chapterId = data.chapterId
    this.clientPrice = data.clientPrice
    this.clientPriceWithoutDiscount = data.clientPriceWithoutDiscount
    this.code = data.code
    this.comment = data.comment
    this.marginCosteGeneral = data.costeGeneral
    this.costeIndirecto = data.costeIndirecto
    this.createdAt = data.createdAt
    this.decomposition = isNil(data.decomposition) ? [] : data.decomposition
    this.description = data.description
    this.discount = data.discount
    this.files = data.files
    this.industrialProfit = data.industrialProfit
    this.marginsEdited = data.marginsEdited
    this.measures = isNil(data.measures) ? [] : data.measures
    this.name = data.name
    this.overage = data.overage
    this.partidaId = data.partidaId
    this.position = data.position
    this.price = data.price
    this.projectId = data.projectId
    this.quantity = data.quantity
    this.refPrice = data.refPrice
    this.sended = data.sended
    this.unity = data.unity
    this.updatedAt = data.updatedAt
    this.cert = data.cert
    // * Data calculated
    this.clientTotalPrice = data.quantity * data.clientPrice
    //this.clientTotalPrice = (data.quantity * data.price) + (data.quantity * data.price * (data.costeGeneral/100))
    this.unitDelta = data.cert?.measure_delta
    this.percentageDelta = (data.cert?.measure_delta * 100) / data.quantity
    this.priceDelta = data.clientPrice * data.cert?.measure_delta
    this.totalPercentage = (data.cert?.measure_total * 100) / data.quantity
    this.totalUnit = data.cert?.measure_total
    this.totalPrice = data.clientPrice * data.cert?.measure_total
  }
}

export default PartCertHistory
