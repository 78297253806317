import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import i18n from "i18n"
import AuthContext from "context/auth/authContext"
import NewModalPartida from "pages/ModalPartidas/NewModalPartida"
import { YellowButton } from "common/buttons"
import BankPricesContext from "context/bankPrices/bankPricesContext"
import { CircularProgress } from "@material-ui/core"
import { getCurrency } from "utils/functions"

const TableStructureBank = ({ children, imParent, parts, capitulo}) => {
    const [openPart, setOpenPart] = useState(false)
    const {user, freeTrialExpired} = useContext(AuthContext)
    const { orderParts, loadingOrder } = useContext(BankPricesContext)
    const createPart = () => {
      setOpenPart(true)
    }
    return(
      <>
        <div id="table-wrapper">
          <div id="table-scroll">
        <table className='tablaPartidas' style={{    marginLeft: imParent ? "0px" : "-8px",
        width: imParent ? "100%" : "100.5%",
        marginTop: imParent ? "0px" : "-10px",
        marginBottom : imParent ? "0px" : "-10px",}}>
 
                  <tr style={{borderBottom:  ' solid #E9ECEF'}}>
                  <th style={{minWidth:'78px', paddingLeft:'15px', borderLeft:'none'}}></th>
                  <th style={{minWidth:'300px', textAlign:'left', paddingLeft:'57px', borderLeft:'none', width:'100%', position:'relative'}}>
                  {  
                    loadingOrder ? <CircularProgress size={14} style={{position:'absolute', top:'10px', right:'10px',color:'#F2A413'}}/> : 
                    <YellowButton onClick={()=>{orderParts(capitulo)}} style={{width:'100px', height:'20px', top:'5px', fontSize:'12px', paddingTop:'0px', position:'absolute', right:'10px'}}>
                      {i18n.t("Ordenar")}
                    </YellowButton>}
                  </th>
                  {/* <th colSpan='2' style={{minWidth:'171px', backgroundColor:'white', color:'rgb(183, 183, 183)'}}>{i18n.t("Histórico")}</th>  */}
                  <th style={{minWidth:'150px'}}>{i18n.t('Precio Unitario')}</th>
                  <th style={{minWidth:'35px', maxWidth:'35px', color:'#c4c4c4', borderLeft:'none'}}></th>
              </tr>
   
  
            <tr id='subCategory'>
            <td style={{borderLeft:'none', width:'79px', color:'#B7B7B7'}}>{i18n.t("Unidad")}</td>
            <td style={{ textAlign:'left', paddingLeft:'35px'}}>{i18n.t("Descripción")}</td>
            
              {/* <td style={{minWidth:'86px', backgroundColor:'white', color:'rgb(183, 183, 183)'}}>€/u</td>
              <td style={{minWidth:'86px', borderLeft: '1px solid #F3F1F1', backgroundColor:'white', color:'rgb(183, 183, 183)'}}>{i18n.t("Total")}</td> */}
            <td style={{minWidth:'127px', color:'#414141', backgroundColor:'white'}}>  {
                getCurrency(user['custom:currency'])
              }/u</td>
            <td style={{minWidth:'35px', maxWidth:'35px', color:'#414141', backgroundColor:'white', borderLeft:'none'}}></td>
        </tr>
         
            {children}
     
            <tr id='lastPartida'>
                <td colSpan='3' style={{borderBottom:'none', textAlign:'left'}} >
                {
                            user ? 
                            freeTrialExpired ||  user['custom:role'] === 'WORKER' ? null : 
                <div className="NombreAlign" style={{left:'5px', justifyContent:'left'}} onClick={()=>{ createPart() }}>
                        <button 
                        data-amplify-analytics-on="click"
                        data-amplify-analytics-name="AddNewPartBP"
                        data-amplify-analytics-attrs={capitulo}
                        className="nuevoBotonCrear" style={{marginTop:'10px', marginBottom:'10px'}}>
                          + {i18n.t("Buscar o crear nueva partida")}
                        </button>
                    </div> : null }
                </td>
              
                 
                </tr> 
          
     
        </table>
        </div>
        </div>
   
         <NewModalPartida open={openPart} setOpen={setOpenPart} chapterId={capitulo.capituloInfo.chapterBankId} bancoDePrecios={'MI BANCO DE PRECIOS'} />
         </>
    )
}

TableStructureBank.propTypes = {
  children: PropTypes.node.isRequired,
  imParent: PropTypes.bool,
  parts: PropTypes.array,
  capitulo: PropTypes.object,
}

export default TableStructureBank
