import PropTypes from "prop-types"
import CDecomposition, { DecompositionPropTypes } from "./CDecomposition"
import CMeasure, { MeasurePropTypes } from "./CMeasure"

class CPart {
  constructor({
    _decomposition,
    accountId,
    bc3file_url,
    cert_current,
    cert_previous,
    chapterId,
    clientPrice,
    clientPriceWithoutDiscount,
    code,
    comment,
    costeGeneral,
    costeIndirecto,
    createdAt,
    decomposition,
    description,
    discount,
    files,
    industrialProfit,
    marginsEdited,
    measures,
    name,
    overage,
    partidaId,
    position,
    price,
    projectId,
    quantity,
    refPrice,
    sended,
    unity,
    updatedAt,
  }) {
    this._decomposition = _decomposition.map(d => new CDecomposition(d))
    this.accountId = accountId
    this.bc3fileUrl = bc3file_url
    this.certCurrent = cert_current
    this.certPrevious = cert_previous
    this.chapterId = chapterId
    this.clientPrice = clientPrice
    this.clientPriceWithoutDiscount = clientPriceWithoutDiscount
    this.code = code
    this.comment = comment
    this.costeGeneral = costeGeneral
    this.costeIndirecto = costeIndirecto
    this.createdAt = createdAt
    this.decomposition = decomposition.map(d => new CDecomposition(d))
    this.description = description
    this.discount = discount
    this.files = files
    this.industrialProfit = industrialProfit
    this.marginsEdited = marginsEdited
    this.measures = measures.map(m => new CMeasure(m))
    this.name = name
    this.overage = overage
    this.partId = partidaId
    this.partidaId = partidaId
    this.position = position
    this.price = price
    this.projectId = projectId
    this.quantity = quantity
    this.refPrice = refPrice
    this.sended = sended
    this.unity = unity
    this.updatedAt = updatedAt
  }
}

export default CPart

const PartPropTypes = PropTypes.shape({
  _decomposition: PropTypes.arrayOf(DecompositionPropTypes),
  accountId: PropTypes.string,
  certCurrent: PropTypes.number,
  certPrevious: PropTypes.number,
  chapterId: PropTypes.string,
  clientPrice: PropTypes.number,
  clientPriceWithoutDiscount: PropTypes.number,
  code: PropTypes.string,
  comment: PropTypes.string,
  costeGeneral: PropTypes.number,
  costeIndirecto: PropTypes.number,
  createdAt: PropTypes.string,
  decomposition: PropTypes.arrayOf(DecompositionPropTypes),
  description: PropTypes.string,
  discount: PropTypes.number,
  files: PropTypes.arrayOf(PropTypes.string),
  industrialProfit: PropTypes.number,
  marginsEdited: PropTypes.string, // TODO: check this
  measures: PropTypes.arrayOf(MeasurePropTypes),
  name: PropTypes.string,
  overage: PropTypes.number,
  partId: PropTypes.string,
  partidaId:PropTypes.string,
  position: PropTypes.number,
  price: PropTypes.number,
  projectId: PropTypes.string,
  quantity: PropTypes.number,
  refPrice: PropTypes.number,
  sended: PropTypes.string, // TODO: check this
  unity: PropTypes.string,
  updatedAt: PropTypes.string,
})

const partPropTypes = PropTypes.shape({
  partId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  partidaId:PropTypes.string,
  accountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  discount: PropTypes.number,
  decomposition: PropTypes.array,
  comment: PropTypes.string,
  refPrice: PropTypes.number,
  projectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  price: PropTypes.number.isRequired,
  quantity: PropTypes.number.isRequired,
  updatedAt: PropTypes.string,
  createdAt: PropTypes.string,
  description: PropTypes.string.isRequired,
  chapterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  elementInfo: PropTypes.any,
  parentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  unity: PropTypes.string.isRequired,
  status: PropTypes.string,
  updates: PropTypes.any,
  images: PropTypes.any,
  attachedFiles: PropTypes.any,
  comeFrom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  position: PropTypes.number,
  measures: PropTypes.array,
  costeGeneral: PropTypes.number,
  costeIndirecto: PropTypes.number,
  industrialProfit: PropTypes.number,
  files: PropTypes.array,
  cert_current: PropTypes.number,
  cert_previous: PropTypes.number,
  clientPriceWithoutDiscount: PropTypes.number,
  creadaEnFinal: PropTypes.bool,
  empty: PropTypes.bool,
  checked: PropTypes.bool,
  decompositionHiden: PropTypes.array,
  clientPrice: PropTypes.number.isRequired,
  clientTotalPrice: PropTypes.number,
}).isRequired

export { PartPropTypes, partPropTypes }
